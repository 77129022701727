import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
import Link from '@nib-components/link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const Copy = makeShortcode("Copy");
const Example = makeShortcode("Example");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Alert type="info" title="This guide references Web Content Accessibility Guidelines (WCAG) 2.1" mdxType="Alert">
  <Copy mdxType="Copy">
    Read the <a href="https://www.w3.org/TR/WCAG21/">Web Content Accessibility Guidelines (WCAG) 2.1</a> to learn more.
  </Copy>
    </Alert>
    <p>{`As designers we know that accessibility is `}<a parentName="p" {...{
        "href": "/getting-started/accessibility/accessibility-statement/"
      }}>{`“something” we need to consider in our work`}</a>{`. Although, being only a consideration and not something we bake into the experiences we create (often in spite of our best intentions), accessibility can be allocated to the margins of our process. As a result, we may be marginalising many of our users and unknowingly be creating poor or even broken experiences.`}</p>
    <br />
    <Link href="https://www.w3.org/" underline="" mdxType="Link">
  <img src="https://www.w3.org/Icons/WWW/w3c_home_nb-v.svg" width="200" alt="W3C logo" />
    </Link>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/"
      }}>{`W3C`}</a>{` have a succinct statement of what web accessibility is:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The Web is fundamentally designed to work for all people, whatever their hardware, software, language, location, or ability. When the Web meets this goal, it is accessible to people with a diverse range of hearing, movement, sight, and cognitive ability.`}</p>
    </blockquote>
    <p>{`From `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/fundamentals/accessibility-intro/"
      }}>{`Introduction to Web Accessibility`}</a></p>
    <p>{`We'll be using the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/"
      }}>{`Web Content Accessibility Guidelines (WCAG)`}</a>{` as the framework for this guide. The guide will provide an overview of WCAG with the goal of encouraging further exploration, with the hope that you will become more confident in applying accessibility guidelines and principles to the next digital experience you deliver to the world. It's worth noting that the principles outlined in the WCAG can be applied to mobile apps as well.`}</p>
    <p>{`This guide serves as that starting point for designing for accessibility and also provides just enough information to uncover the fundamental principles with plenty of links to visit and explore.`}</p>
    <h2 {...{
      "id": "a-brief-history",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-brief-history",
        "aria-label": "a brief history permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`A brief history`}</h2>
    <figure>
  <img src="/36a37006fc6b557b02d62672b7b43b0e/640px-At_the_Science_Museum_for_the_Web@30_event,_March_2019_23.jpg" alt="Tim Berners-Lee at the Science Museum for the Web@30 event, March 2019" />
  <figcaption>
    Tim Berners-Lee at the Science Museum for the Web@30 event, March 2019. Image credit: Jwslubbock - Own work, <a href="https://commons.wikimedia.org/w/index.php?curid=77579144.">CC BY-SA 4.0</a>
  </figcaption>
    </figure>
    <p>{`Before we continue to the Web Content Accessibility Guidelines (WCAG), it will be beneficial to have a brief history lesson and find out when the web accessibility initiative started.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/"
      }}>{`World Wide Web Consortium (W3C)`}</a>{` was founded in 1994 and became the international standards organisation for the World Wide Web. The W3C was founded by `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Tim_Berners-Lee"
      }}>{`Time Berners-Lee`}</a>{` who is also credited for being the inventor of the World Wide Web.`}</p>
    <p>{`Several years later in 1999, the W3C created a project known as the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/"
      }}>{`Web Accessibility Initiative (WAI)`}</a>{` as an effort to improve the web for people with disabilities. On 5 May 1999 the WAI published the first Web Content Accessibility Guidelines known as WCAG 1.0. The latest official version of WCAG is now at 2.1 with `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/standards-guidelines/wcag/wcag3-intro/"
      }}>{`3.0 currently in working draft`}</a>{` status.`}</p>
    <p>{`Now that we have that short history under our belt, let's check out the current Web Content Accessibility Guidelines.`}</p>
    <h2 {...{
      "id": "web-content-accessibility-guidelines-wcag-21",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#web-content-accessibility-guidelines-wcag-21",
        "aria-label": "web content accessibility guidelines wcag 21 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Web Content Accessibility Guidelines (WCAG) 2.1`}</h2>
    <Alert type="info" title="Did you know?" mdxType="Alert">
  <Copy mdxType="Copy">
    Under the Disability Discrimination Act 1992, Australian Government agencies are required to ensure information and services are provided in a non-discriminatory accessible manner. Read more at{' '}
    <a href="https://info.australia.gov.au/accessibility">info.australia.gov.au/accessibility</a>.
  </Copy>
    </Alert>
    <p>{`The best place to learn more about the purpose of the Web Content Accessibility Guidelines (WCAG) is from the source itself. The following paragraph has been taken from the guidelines and provides a clear overview of the subject matter the documentation covers:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Web Content Accessibility Guidelines (WCAG) 2.1 covers a wide range of recommendations for making Web content more accessible. Following these guidelines will make content more accessible to a wider range of people with disabilities, including accommodations for blindness and low vision, deafness and hearing loss, limited movement, speech disabilities, photosensitivity, and combinations of these, and some accommodation for learning disabilities and cognitive limitations; but will not address every user need for people with these disabilities. These guidelines address accessibility of web content on desktops, laptops, tablets, and mobile devices. Following these guidelines will also often make Web content more usable to users in general.`}</p>
    </blockquote>
    <p>{`From `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#abstract"
      }}>{`Web Content Accessibility Guidelines (WCAG) Abstract`}</a></p>
    <p>{`It's worth while contemplating the above paragraph and considering the different types of users, disabilities and situations described. When producing your next design think about these users and what might we do to improve their experience. That could be applying appropriate contrast ratios for people with low vision, or arranging content in a manner that makes sense for screen reading technology and keeping language simple and clear for easy comprehension.`}</p>
    <h3 {...{
      "id": "the-wcag-layers-of-guidance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-wcag-layers-of-guidance",
        "aria-label": "the wcag layers of guidance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`The WCAG layers of guidance`}</h3>
    <p>{`An important section of the guidelines are the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#wcag-2-layers-of-guidance"
      }}>{`layers of guidance`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The individuals and organizations that use WCAG vary widely and include Web designers and developers, policy makers, purchasing agents, teachers, and students. In order to meet the varying needs of this audience, several layers of guidance are provided including overall principles, general guidelines, testable success criteria and a rich collection of sufficient techniques, advisory techniques, and documented common failures with examples, resource links and code.`}</p>
    </blockquote>
    <p>{`From `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#wcag-2-layers-of-guidance"
      }}>{`layers of guidance`}</a>{`.`}</p>
    <p>{`The overarching layer of the layers of guidance are the four accessibility principles. All other layers fit within each of the principles.`}</p>
    <h2 {...{
      "id": "principles-of-accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#principles-of-accessibility",
        "aria-label": "principles of accessibility permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Principles of accessibility`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/fundamentals/accessibility-principles/"
      }}>{`four accessibility principles`}</a>{` are outlined below with an example for each provided from the Mesh design system.`}</p>
    <p>{`Examples provided are short and succinct, so we highly encourage you to explore the guidelines by visiting the links provided to attain a deeper understanding of designing for accessibility specific to your design.`}</p>
    <p>{`Each example supplied has an accompanying table that states the conformance level met. For further information about conformance visit the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#conformance-to-wcag-2-1"
      }}>{`conformance section`}</a>{` within the WCAG.`}</p>
    <h3 {...{
      "id": "principle-1-perceivable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#principle-1-perceivable",
        "aria-label": "principle 1 perceivable permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Principle 1: `}<a parentName="h3" {...{
        "href": "https://www.w3.org/WAI/fundamentals/accessibility-principles/#perceivable"
      }}>{`Perceivable`}</a></h3>
    <p>{`Information and user interface components must be presentable to users in ways they can perceive.`}</p>
    <p>{`There are numerous ways users perceive information we present in our interfaces, that might be by providing text alternatives for non-text content such as images or icons. Content can also be presented in different ways, for example; the sequence of content is arranged in a way that can be comprehended by both visually able users and screen reading technology.`}</p>
    <p><strong parentName="p">{`Example:`}</strong>{` Mesh `}<a parentName="p" {...{
        "href": "/components/alert/"
      }}>{`alert`}</a>{` component.`}</p>
    <Example mdxType="Example">
  <Alert title="Success! Your cover has now been updated." type="success" mdxType="Alert">
    <Link href="#" mdxType="Link">Back to your account overview</Link>
  </Alert>
    </Example>
    <Example mdxType="Example">
  <Alert title="Your last name is missing." type="error" mdxType="Alert">
    We need your last name to locate your policy.
  </Alert>
    </Example>
    <p>{`In this example, the following criteria is one of many areas that must be addressed in order to satisfy the Perceivable principle.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Success criteria`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Conformance`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://www.w3.org/TR/WCAG21/#use-of-color"
            }}>{`1.4.1 Use of Color`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Level A`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color is not used as the only visual means of conveying information, indicating an action, prompting a response, or distinguishing a visual element.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "principle-2-operable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#principle-2-operable",
        "aria-label": "principle 2 operable permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Principle 2: `}<a parentName="h3" {...{
        "href": "https://www.w3.org/WAI/fundamentals/accessibility-principles/#operable"
      }}>{`Operable`}</a></h3>
    <p>{`User interface components and navigation must be operable.`}</p>
    <p>{`There are many users who use a keyboard only to navigate the web. Consideration for these users should be given towards the focus order of navigation links and other page elements. A user should be able to determine where they are within a website or web page. Clear titles and descriptive headings help users navigate content, and also ensure the purpose of links are easily understood.`}</p>
    <p><strong parentName="p">{`Example:`}</strong>{` Mesh `}<a parentName="p" {...{
        "href": "/components/header/"
      }}>{`header`}</a>{` component.`}</p>
    <p><img parentName="p" {...{
        "src": "/5676f5514f7280496faa65e3b173b1fd/operable-nav-focus.png",
        "alt": "Navigation focus"
      }}></img></p>
    <p>{`In this example, the following criteria is one of many areas that must be addressed in order to satisfy the Operable principle.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Success criteria`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Conformance`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://www.w3.org/TR/WCAG21/#focus-order"
            }}>{`2.4.3 Focus Order`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Level A`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If a Web page can be navigated sequentially and the navigation sequences affect meaning or operation, focusable components receive focus in an order that preserves meaning and operability.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "principle-3-understandable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#principle-3-understandable",
        "aria-label": "principle 3 understandable permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Principle 3: `}<a parentName="h3" {...{
        "href": "https://www.w3.org/WAI/fundamentals/accessibility-principles/#understandable"
      }}>{`Understandable`}</a></h3>
    <p>{`Information and the operation of the user interface must be understandable.`}</p>
    <p>{`Consistent labeling of interface elements like icons reduces the cognitive load of a user and helps establish a predictable pattern of information. Acronyms, unusual wording or industry specific terms should be avoided when not relevant within the context of the content, and when used, definitions should also be provided.`}</p>
    <p><strong parentName="p">{`Example:`}</strong>{` Mesh `}<a parentName="p" {...{
        "href": "/iconography/graphic-icons/"
      }}>{`graphic icons`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/daac966df76245725b92234328f49726/understandable-icons.png",
        "alt": "Icons with clear labels"
      }}></img></p>
    <p>{`In this example, the following criteria is one of many areas that must be addressed in order to satisfy the Understandable principle.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Success criteria`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Conformance`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://www.w3.org/TR/WCAG21/#consistent-identification"
            }}>{`3.2.4 Consistent Identification`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Level AA`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Components that have the same functionality within a set of Web pages are identified consistently.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "principle-4-robust",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#principle-4-robust",
        "aria-label": "principle 4 robust permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Principle 4: `}<a parentName="h3" {...{
        "href": "https://www.w3.org/WAI/fundamentals/accessibility-principles/#robust"
      }}>{`Robust`}</a></h3>
    <p>{`Content must be robust enough that it can be interpreted by a wide variety of user agents, including assistive technologies.`}</p>
    <p>{`This also means providing a name, role, and value for non-standard user interface components. When designing forms, provide descriptive labels and validation messages for input fields and other relevant controls.`}</p>
    <p><strong parentName="p">{`Example:`}</strong>{` Mesh `}<a parentName="p" {...{
        "href": "/components/form-control/"
      }}>{`form control`}</a>{` component.`}</p>
    <p><img parentName="p" {...{
        "src": "/b5afd0cf6d3f141b3bf1855e690a4143/robust-form-feedback.png",
        "alt": "Icons with clear labels"
      }}></img></p>
    <p>{`In this example, the following criteria is one of many areas that must be addressed in order to satisfy the Robust principle.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Success criteria`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Conformance`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://www.w3.org/TR/WCAG21/#status-messages"
            }}>{`4.1.3 Status Messages`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Level AA`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`In content implemented using markup languages, status messages can be programmatically determined through role or properties such that they can be presented to the user by assistive technologies without receiving focus.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "where-to-from-here",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#where-to-from-here",
        "aria-label": "where to from here permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Where to from here?`}</h2>
    <p>{`It may feel like there is a lot of documentation to dig through which can feel overwhelming. Our hope is that we have provided a starting point for further exploration. When designing your experience, take advantage of the W3C guidelines. Better yet, bookmark them for handy reference. Apply the principles when and where you see they make sense. Always ensure interfaces have sufficient contrast, simple language and enough whitespace so as to not overwhelm your users. Designing for inclusion leads to better experiences for everyone.`}</p>
    <p>{`It's better to be brave and apply these principles when you can and still fall short, than to not attempt at all. The goal is to create a more inclusive experience with each effort we invest.`}</p>
    <h2 {...{
      "id": "the-a11y-project",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-a11y-project",
        "aria-label": "the a11y project permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`The a11y project`}</h2>
    <Alert type="info" title="Check your WCAG compliance" mdxType="Alert">
  <Copy mdxType="Copy">
    Check your WCAG compliance with <a href="https://www.a11yproject.com/checklist/">a11y project's checklist</a>.
  </Copy>
    </Alert>
    <p>{`If you still feel a little overwhelmed and need a more gentle place to start, the a11y project can help.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Accessibility is incredibly important, yet often overlooked in traditional digital design and development education. Because of this, The A11Y Project strives to be a living example of how to create beautiful, accessible, and inclusive digital experiences.`}</p>
    </blockquote>
    <p>{`From the `}<a parentName="p" {...{
        "href": "https://www.a11yproject.com/about/"
      }}>{`About page`}</a>{` at The a11y project website.`}</p>
    <p>{`The a11y project provides `}<a parentName="p" {...{
        "href": "https://www.a11yproject.com/resources/"
      }}>{`resources`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.a11yproject.com/checklist/"
      }}>{`tools`}</a>{` to help you get started and to make designing for accessibility easier.`}</p>
    <p>{`Also, if you just need advice, designops is here to help!`}</p>
    <h2 {...{
      "id": "further-reading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#further-reading",
        "aria-label": "further reading permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Further reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.a11yproject.com/posts/"
        }}>{`A11y project blog posts`}</a></li>
      <li parentName="ul">{`W3C Web Accessibility Initiative (WAI):`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.w3.org/WAI/tips/writing/"
            }}>{`Writing for Web Accessibility`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.w3.org/WAI/tips/designing/"
            }}>{`Designing for Web Accessibility`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.w3.org/WAI/tips/developing/"
            }}>{`Developing for Web Accessibility`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/WCAG21/"
        }}>{`Web Content Accessibility Guidelines (WCAG) 2.1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wcag-3.0/"
        }}>{`WCAG 3.0 public working draft`}</a></li>
    </ul>
    <h2 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/2009/cheatsheet/"
        }}>{`W3C Cheat Sheet`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.a11yproject.com/checklist/"
        }}>{`A11y accessibility checklist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.a11yproject.com/resources/"
        }}>{`A11y tools and resources`}</a></li>
    </ul>
    <h2 {...{
      "id": "credits",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#credits",
        "aria-label": "credits permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Credits`}</h2>
    <p>{`Author: Michael Troy`}</p>
    <p>{`Reviewers: Laurie Jones, Jordan Collins`}</p>
    <p>{`Published: 14 April 2021`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      